<template>
  <div class="ma-5">
    <div
      v-if="isCustom"
      class="h-pricing-card__container"
      :class="{ active: item.active }"
      @click="selectPlan"
    >
      <div class="h-pricing-card__title">
        {{ item.name }}
      </div>

      <div class="h-pricing-card__description my-3">
        <v-alert
          outlined
          color="grey"
          text
        >
          Precisa de tarifas mais acessíveis?
          Entre em contato agora com a nossa equipe pelo WhatsApp!
        </v-alert>
      </div>

      <div class="h-pricing-card__description">
        <v-btn
          color="success mb-5"
          href="https://contate.me/strongexpert-planos"
          target="_blank"
        >
          <v-icon
            left
            dark
          >
            {{ mdiWhatsapp }}
          </v-icon>
          Contato
        </v-btn>
      </div>
    </div>
    <div
      v-else
      class="h-pricing-card__container"
      @click="selectPlan"
    >
      <div class="h-pricing-card__title">
        {{ item.name }}
      </div>
      <div class="h-pricing-card__separator"></div>

      <div class="h-pricing-card__features--grouped mt-5">
        <div
          v-for="(tax, indexT) in item.tax"
          :key="indexT"
          class="h-plan-feature"
        >
          <div class="h-plan-feature__content">
            <div class="h-plan-feature__content--service">
              {{ tax.service.name }}
            </div>
            <div class="h-plan-feature__content--value">
              R$ {{ $money(tax.unitaryValue) }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mdiWhatsapp } from '@mdi/js'

export default {
  props: {
    currentValue: {
      type: String,
      default: '',
    },
    item: {
      type: Object,
      default: () => {},
    },
    isCurrent: {
      type: Boolean,
      default: false,
    },
    isCustom: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    value() {
      return this.item.minValue - Number(this.currentValue ?? 0)
    },
  },
  setup() {
    return {
      mdiWhatsapp,
    }
  },
  methods: {
    selectPlan() {
      this.item.active = true
      this.$emit('selectPlan', this.item.id)
    },
  },
}
</script>

<style scoped lang="scss">
.h-pricing-card__container {
  transition: 0.5s ease-in-out;
  padding: 20px 20px 0px 20px;
  align-items: center;
  background-color: #fff;
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  max-width: 380px;
  outline: 1px solid #d5dfff;

  &.active {
    outline: 3px solid #60bdd3;
    background-color: #60bdd31a;
    transform: scale(1.02);
  }

  &:hover:not(.active) {
    box-shadow: 0 2px 15px #00000026;
    transform: scale(1.02);
  }

  .h-pricing-card__title {
    color: #56c8b5;
    font-size: 24px;
    font-weight: 700;
    grid-area: auto;
    line-height: 32px;
    margin: 0px 0px 4px;
    text-align: center;
  }

  .h-pricing-card__description {
    color: #5e5669de;
    font-size: 14px;
    font-weight: 100;
    line-height: 24px;
    margin: 0px 0px 5px;
    text-align: center;
  }

  .h-pricing-card__discount {
    min-height: 32px;
    .h-value {
      display: inline-flex;
      .h-value__currency {
        font-size: 0.416667em;
        color: #727586;
        font-weight: 100;
        grid-area: auto;
        line-height: 24px;
        margin: 0px 4px 0px 0px;
        text-align: center;
      }
      .h-value__number {
        color: #727586;
        font-size: 14px;
        font-weight: 100;
        grid-area: auto;
        line-height: 24px;
        text-align: center;
      }
    }
    .h-pricing-card__discount--tag {
      font-size: 16px;
      margin-left: 4px;
      font-weight: 700;
    }
    .h-discount-tag-bg-primary-light {
      background-color: #ef5858;
      border-radius: 20px;
      padding: 4px 12px;
      color: white;
    }
    .h-discount-tag {
      border-radius: 20px;
      display: inline-block;
      padding: 4px 12px;
    }
  }

  .h-pricing-card__current-value {
    font-size: 48px;
    color: #5e5669de;
    font-weight: bold;
    grid-area: auto;
    text-align: center;
    .h-pricing-card__value-info {
      color: #5e5669de;
      font-size: 14px;
      font-weight: 100;
      grid-area: auto;
      line-height: 24px;
      text-align: center;
    }
    .h-pricing-card__separator {
      border: 0.5px solid #d5dfff;
      margin-bottom: 32px;
      width: 100%;
    }
  }
  .h-pricing-card__features--grouped {
    margin: 0 0 32px;
    width: 100%;

    .h-pricing-card__feature-title {
      text-align: center;
      color: #56c8b5;
      font-size: 18px;
      font-weight: 700;
      grid-area: auto;
      line-height: 24px;
      margin: 0px 0px 24px;
    }

    .h-plan-feature {
      margin-bottom: 12px;
      display: flex;
      justify-content: space-between;

      &:nth-child(even) {
        background-color: #f2f2f2;
        border-radius: 20px;
      }

      .h-plan-feature__content {
        align-items: center;
        display: flex;
        justify-content: space-between;
        text-align: start;
        width: 100%;

        color: #5e5669de;
        font-size: 14px;
        font-weight: 100;
        grid-area: auto;
        line-height: 24px;

        .h-plan-feature__content--service {
          width: 50%;
          text-align: center;
        }

        .h-plan-feature__content--value {
          width: 50%;
          text-align: center;
          background: -webkit-linear-gradient(#06f11a, #24bb9a, #3899eb);
          font-weight: bold;
          background-clip: text;
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
        }
      }
    }
  }
}
.description-money {
  font-size: 16px;
  color: #5e5669de;
  font-weight: bold;
}
.theme--dark.v-application {
  .t-header-currency,
  .h-value__currency,
  .h-plan-feature__content--service,
  .h-pricing-card__description,
  .description-money,
  .h-plan-feature__content--value {
    color: white !important;
  }

  .h-pricing-card__container {
    background-color: #60bdd314;

    &.active {
      background-color: #60bed342;
    }
  }

  .h-pricing-card__container .h-pricing-card__features--grouped .h-plan-feature {
    background-color: #374047;
    border-radius: 20px;
  }
}
</style>
