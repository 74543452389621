export default {
  rows: [],
  row: {},
  roles: [
    { id: 1, text: 'Administrador' },
    { id: 2, text: 'Cliente' },
  ],
  levels: [
    { id: 1, text: 'Basic' },
    { id: 2, text: 'Starter' },
    { id: 4, text: 'Advanced' },
    { id: 6, text: 'Elite' },
  ],
}
