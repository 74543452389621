<template>
  <div
    class="auth-page"
    style="height: auto;"
  >
    <div class="auth-wrapper auth-v1">
      <div class="auth-inner">
        <v-card
          class="auth-card"
        >
          <v-card-title class="d-flex align-center justify-center py-7">
            <router-link
              to="/"
              class="d-flex align-center"
            >
              <h2 class="text-2xl font-weight-semibold">
                STRONG EXPERT
              </h2>
            </router-link>
          </v-card-title>

          <v-card-text>
            <v-alert
              v-if="hasInitialCredit"
              dense
              text
              outlined
              type="success"
            >
              Garanta seu bônus especial ao se cadastrar agora! <br />
              Experimente nossa plataforma e veja como podemos transformar seus resultados! <br />
            </v-alert>
            <validation-observer
              ref="form"
              autocomplete="off"
              tag="form"
              @keyup.enter="register()"
            >
              <validation-provider
                v-slot="{ errors }"
                name="Nome"
                rules="required|max:100"
                vid="name"
              >
                <v-text-field
                  v-model="name"
                  :error-messages="errors"
                  outlined
                  dense
                  label="Nome"
                />
              </validation-provider>

              <validation-provider
                v-slot="{ errors }"
                name="E-mail"
                rules="required|email"
                vid="email"
              >
                <v-text-field
                  v-model="email"
                  :error-messages="errors"
                  outlined
                  dense
                  label="E-mail"
                />
              </validation-provider>

              <validation-provider
                v-slot="{ errors }"
                name="Senha"
                rules="required"
                vid="password"
              >
                <v-text-field
                  v-model="password"
                  :append-icon="isPasswordVisible ? icons.mdiEyeOffOutline : icons.mdiEyeOutline"
                  :error-messages="errors"
                  :type="isPasswordVisible ? 'text' : 'password'"
                  label="Senha"
                  outlined
                  dense
                  @click:append="isPasswordVisible = !isPasswordVisible"
                />
              </validation-provider>

              <validation-provider
                v-slot="{ errors }"
                name="Confirmar Senha"
                rules="required|confirmed:password"
                vid="passwordConfirmation"
              >
                <v-text-field
                  v-model="passwordConfirmation"
                  :append-icon="isPasswordConfirmationVisible ? icons.mdiEyeOffOutline : icons.mdiEyeOutline"
                  :error-messages="errors"
                  :type="isPasswordConfirmationVisible ? 'text' : 'password'"
                  label="Confirmar Senha"
                  outlined
                  dense
                  @click:append="isPasswordConfirmationVisible = !isPasswordConfirmationVisible"
                />
              </validation-provider>
              <validation-provider
                v-slot="{ errors }"
                name="Política de privacidade"
                rules="checkbox_required"
                vid="privacyPolicy"
              >
                <div style="display: flex; align-items: center;">
                  <v-checkbox
                    v-model="privacyPolicy"
                    style="margin-bottom: 6px;"
                    :error-messages="errors"
                    label=""
                    hide-details
                    dense
                  />
                  <span> Li e concordo com a <a
                    href="https://strong.expert/politica-de-privacidade/"
                    style="color:lightblue"
                  >Política de
                    Privacidade</a></span>
                </div>
              </validation-provider>
              <validation-provider
                v-slot="{ errors }"
                name="Termos de usuário"
                rules="checkbox_required"
                vid="acceptTerms"
              >
                <div style="display: flex; align-items: center; margin-bottom: 10px;">
                  <v-checkbox
                    v-model="acceptTerms"
                    style="margin-bottom: 6px;"
                    :error-messages="errors"
                    label=""
                    hide-details
                    dense
                  />

                  <span>Li e concordo com os <a
                    href="https://strong.expert/termos-de-uso/"
                    style="color:lightblue"
                  >
                    Termos
                    de Uso</a></span>
                </div>
              </validation-provider>
              <v-btn
                block
                color="primary"
                @click="register()"
              >
                Cadastrar
              </v-btn>

              <div class="d-flex align-center justify-center flex-wrap mt-6">
                <div
                  id="g_id_onload"
                  :data-client_id="googleClientId"
                  data-context="signin"
                  data-ux_mode="popup"
                  data-callback="handleCredentialResponse"
                  data-auto_prompt="false"
                >
                </div>

                <div
                  class="g_id_signin"
                  data-type="standard"
                  data-shape="pill"
                  data-theme="outline"
                  data-text="continue_with"
                  data-size="large"
                  data-logo_alignment="left"
                >
                </div>
              </div>
            </validation-observer>
          </v-card-text>

          <v-card-text class="d-flex align-center justify-center flex-wrap mt-2">
            <span class="me-2">
              Já tem uma conta?
            </span>
            <router-link :to="{ name: 'signin' }">
              Faça login
            </router-link>
          </v-card-text>
        </v-card>
      </div>

      <div
        v-for="index in 6"
        :key="index"
        class="cube"
      />
    </div>
  </div>
</template>

<script>
import axios from 'axios'
import { mdiEyeOutline, mdiEyeOffOutline } from '@mdi/js'
import { ref } from '@vue/composition-api'
import { extend } from 'vee-validate'
import { mapActions } from 'vuex'
import { startCredit, userAuthMethod } from '@/utils/enum'

export default {
  setup() {
    const isPasswordVisible = ref(false)
    const isPasswordConfirmationVisible = ref(false)
    const name = ref('')
    const email = ref('')
    const password = ref('')
    const passwordConfirmation = ref('')
    const privacyPolicy = ref(false)
    const acceptTerms = ref(false)
    const hasInitialCredit = ref(false)
    const googleClientId = '406454925828-c97a9gbt4kg9l0jfbv9d0mon6r16o33e.apps.googleusercontent.com'

    extend('checkbox_required', {
      validate: value => value,
      message: 'Este campo é obrigatório',
    })

    return {
      isPasswordVisible,
      isPasswordConfirmationVisible,
      name,
      email,
      password,
      passwordConfirmation,
      privacyPolicy,
      acceptTerms,
      hasInitialCredit,
      googleClientId,
      icons: {
        mdiEyeOutline,
        mdiEyeOffOutline,
      },
    }
  },

  created() {
    if (this.$route.query.tag === startCredit.sent50) {
      this.hasInitialCredit = true
    }
  },

  mounted() {
    this.loadGoogleScript()

    window.handleCredentialResponse = this.handleCredentialResponse
  },

  methods: {
    ...mapActions('auth', [
      'signup',
      'gAuthSignup',
      'gAuthSignin',
    ]),

    getUtmParams() {
      const params = new URLSearchParams(window.location.search)

      return {
        utm_source: params.get('utm_source'),
        utm_medium: params.get('utm_medium'),
        utm_campaign: params.get('utm_campaign'),
        utm_term: params.get('utm_term'),
        utm_content: params.get('utm_content'),
        GCLID: params.get('GCLID'),
      }
    },

    async handleCredentialResponse(response) {
      const token = response.credential

      const status = await this.signup({
        privacyPolicy: this.privacyPolicy,
        acceptTerms: this.acceptTerms,
        role: 2, // Cliente
        customerLevelId: 1,
        hasInitialCredit: this.hasInitialCredit,
        affiliateTenant: this.$route.query.tenant,
        authMethod: userAuthMethod.GoogleAuth,
        idToken: token,
      })

      if (status === 201) {
        const res = await this.gAuthSignin({ token })

        if (res.token) {
          await this.sendUtmInfo()
          this.$router.push('/')

          this.$store.commit('app/setNotify', {
            visible: false,
            color: '',
            message: '',
            time: -1,
          })
        }
      }
    },

    loadGoogleScript() {
      const script = document.createElement('script')
      script.src = 'https://accounts.google.com/gsi/client'
      script.async = true
      script.defer = true
      document.head.appendChild(script)
    },

    async register() {
      if (await this.$refs.form.validate()) {
        const status = await this.signup({
          name: this.name,
          email: this.email,
          password: this.$sha256(this.password),
          privacyPolicy: this.privacyPolicy,
          acceptTerms: this.acceptTerms,
          role: 2, // Cliente
          customerLevelId: 1,
          hasInitialCredit: this.hasInitialCredit,
          affiliateTenant: this.$route.query.tenant,
          authMethod: userAuthMethod.System,
        })

        if (status === 201) {
          await this.sendUtmInfo()
          this.$router.push('/signin')
        }
      }
    },

    async sendUtmInfo() {
      const params = this.getUtmParams()

      await axios.post('https://strongexpert.app.n8n.cloud/webhook/62f3dd57-3e29-46ab-9a38-2fdac0375faf', {
        ...params,
        email: this.email,
      })
    },
  },
}
</script>

<style lang="scss">
@import '~@/plugins/vuetify/default-preset/preset/pages/auth.scss';
</style>
